import React from "react"
import { Link, graphql } from "gatsby"
import { RiArrowRightLine, RiArrowLeftLine } from "react-icons/ri"
import Layout from "../components/layout"
import SEO from "../components/seo"
//import { FacebookProvider, Comments } from "react-facebook"
import { siteMetadata } from "../../gatsby-config"
import SocialHeader from "../components/social-header"
import Chip from "@material-ui/core/Chip"
import { OutboundLink } from "gatsby-plugin-gtag"
import MyCommentBox from "../components/comment-box"
import { createClient } from "contentful"
import {sluger} from "../util/sluger"
//import "prismjs/themes/prism-tomorrow.css"
import {TinyButton as ScrollUpButton} from "react-scroll-up-button"

const Pagination = props => (
  <div className="pagination-strict">
    <div className="pagination-posted -post">
      <ul>
        {props.previous && props.previous.frontmatter.template === "blog-post" && (
          <li>
            <Link to={props.previous.frontmatter.slug ? props.previous.frontmatter.slug : sluger(props.previous.frontmatter.title,"/blog/")} rel="prev">
              <p>
                <span className="icon -left">
                  <RiArrowLeftLine />
                </span>{" "}
                Previous
              </p>
              <span className="page-title">
                {props.previous.frontmatter.title}
              </span>
            </Link>
          </li>
        )}
        {props.next && props.next.frontmatter.template === "blog-post" && (
          <li>
            {props.next.frontmatter.date != null ? (
              <Link to={props.next.frontmatter.slug ? props.next.frontmatter.slug : sluger(props.next.frontmatter.title,"/blog/")} rel="next">
                <p>
                  Next{" "}
                  <span className="icon -right">
                    <RiArrowRightLine />
                  </span>
                </p>
                <span className="page-title">
                  {props.next.frontmatter.title}
                </span>
              </Link>
            ) : (
              ""
            )}
          </li>
        )}
      </ul>
    </div>
  </div>
)

const postData = (func, data) => {
  return (
    fetch(`${process.env.GATSBY_CONTENTFUL_LAMBDA_URL}${func}`, {
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
      //mode: 'cors' // if your endpoints are on a different domain
    })
      .then(response => response.json())
      .catch(console.error)
  )
}

const updateData = (func, data) => {
  return (
    fetch(`${process.env.GATSBY_CONTENTFUL_LAMBDA_URL}${func}`, {
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
      method: "PUT",
      //mode: 'cors' // if your endpoints are on a different domain
    })
      .then(response => response.json())
      .catch(console.error)
  )
}

const deleteData = (func, data) => {
  return (
    fetch(`${process.env.GATSBY_CONTENTFUL_LAMBDA_URL}${func}`, {
      body: JSON.stringify(data),
      headers: {
        "content-type": "application/json",
      },
      method: "DELETE",
      //mode: 'cors' // if your endpoints are on a different domain
    })
      .then(response => response.json())
      .catch(console.error)
  )
}

const contentfulClient = createClient({
  space: process.env.GATSBY_APP_CONTENTFUL_SPACE_ID,
  accessToken: process.env.GATSBY_APP_CONTENTFUL_ACCESS_TOKEN,
  host: process.env.GATSBY_APP_CONTENTFUL_HOST,
})

const Post = ({ data, pageContext}) => {
  if (data.markdownRemark == null) return ""

  const { markdownRemark } = data // data.markdownRemark holds your post data
  const { frontmatter, html, excerpt } = markdownRemark
  const { previous, next } = pageContext
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.resize
    : ""
  // const currentURL = typeof window !== `undefined` ? window.location.protocol + "//" + window.location.host + window.location.pathname : ""
  const { siteUrl } = siteMetadata
  const currentSlug = frontmatter.slug ? frontmatter.slug : sluger(frontmatter.title,"/blog/")
  const currentURL = `${siteUrl}${currentSlug}`
  const blogPostID = currentSlug

  const chips = frontmatter.tags.map(element => (
    <Link key={element} to={sluger(element,"/tag/")}>
      <Chip label={element} className="chip" />
    </Link>
  ))

  let props = {
    previous,
    next,
  }

  return (
    <Layout className="page">
      <ScrollUpButton style={{outline:"none",background:"#D5D5D5"}} ToggledStyle={{opacity:0.25}}/>
      <SEO
        title={frontmatter.title}
        description={
          frontmatter.description ? frontmatter.description : excerpt
        }
        image={!Image.src.includes("def_post.jpg") ? Image.src : null}
        article={true}
      />
      <article className="blog-post">
        <header className="featured-banner">
          <section className="article-header">
            <h1>{frontmatter.title}</h1>
            <div></div>
          </section>
          <SocialHeader
            data={data}
            currentURL={currentURL}
            facebook_appID={siteMetadata.facebook_appID}
          />
        </header>
        <div
          className="blog-post-content"
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </article>
      <div className="buy-coffee">
        <OutboundLink
          href="https://www.buymeacoffee.com/garlenjavier"
          target="_blank"
          rel="noreferrer noopener"
        >
          <img
            src="https://cdn.buymeacoffee.com/buttons/v2/default-black.png"
            alt="Buy Me A Coffee"
            className="bmc-button"
          />
        </OutboundLink>{" "}
      </div>
      <div className="chips-array">{chips}</div>
      {(previous || next) && <Pagination {...props} />}
      <SocialHeader
        data={data}
        currentURL={currentURL}
        facebook_appID={siteMetadata.facebook_appID}
        isFooter={true}
      />
      <div className="divider" />
      <div className="comments">
          <MyCommentBox
            currentURL={currentURL}
            subjectId={blogPostID}
            postData={postData}
            updateData={updateData}
            deleteData={deleteData}
            contentfulClient={contentfulClient}
          />
      </div>
      {/* <div className="comments">
        <FacebookProvider appId={siteMetadata.facebook_appID} >
          <Comments href={currentURL} width="100%"/>
        </FacebookProvider>
      </div> */}
    </Layout>
  )
}

export default Post

export const pageQuery = graphql`
  query BlogPostQuery($id: String!) {
    markdownRemark(id: { eq: $id }, frontmatter: { date: { ne: null } }) {
      id
      html
      excerpt(pruneLength: 160)
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        slug
        description
        tags
        author
        authorDescription
        authorImage {
          childImageSharp {
            fluid(maxWidth: 52, maxHeight: 52, quality: 80) {
              ...GatsbyImageSharpFluid
              ...GatsbyImageSharpFluidLimitPresentationSize
            }
          }
        }
        featuredImage {
          childImageSharp {
            resize(width: 600, height: 315) {
              src
              height
              width
            }
          }
        }
      }
    }
  }
`
