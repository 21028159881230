import React from "react"
import {
  FaFacebookSquare,
  FaTwitterSquare,
  // FaGithub,
  FaLinkedin,
  // FaGoogle,
  FaGithubSquare,
} from "react-icons/fa"
import { ImGoogle2 } from "react-icons/im"
// import { FcGoogle } from "react-icons/fc"
import { v4 as uuidv4 } from "uuid"

var CryptoJS = require("crypto-js")

class SocialLogin extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)
    this.onPop = this.onPop.bind(this)
  }

  static get defaultProps() {
    const { responseCallback, currentURL } = this
    return {
      responseCallback,
      currentURL,
    }
  }

  componentDidMount() {
    this._isMounted = true

    const self = this
    if (typeof window !== `undefined`) {
      window.addEventListener("storage", () => {
        const e = window.localStorage.getItem("loginCallback")
        if (e && e !== "") {
          if (this._isMounted) {
            self.props.responseCallback(e)
            window.localStorage.setItem("loginCallback", "")
          }
        }
      })
    }
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  onPop(e, loginName) {
    var _token = uuidv4()
    window.localStorage.setItem("token_pass", _token)

    var encrypted = CryptoJS.AES.encrypt(
      _token,
      process.env.GATSBY_TOKEN_PASSPHRASE
    ).toString()
    var base64 = Buffer.from(encrypted).toString("base64")

    var url = `${process.env.GATSBY_LAMBDA_URL}/.netlify/functions/auth/${loginName}?host=${this.props.currentURL}&token=${base64}`
    var width = 600
    var height = 600

    var win = typeof window !== `undefined` ? window : null
    var left =
      win.outerWidth / 2 + (win.screenX || win.screenLeft || 0) - width / 2
    var top =
      win.outerHeight / 2 + (win.screenY || win.screenTop || 0) - height / 2
    var n =
      typeof window !== `undefined`
        ? win.open(
            url,
            "_blank",
            "toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width=" +
              width +
              ", height=" +
              height +
              ", top=" +
              top +
              ", left=" +
              left
          )
        : null
    //var n = win.open(url,'_blank');
    if (n == null) {
      return true
    }
    return false
  }

  render() {
    return (
      <span className="social-login">
        <span className="text">Login to post</span>
        <span className="icons">
          <FaFacebookSquare
            onClick={e => this.onPop(e, "facebook")}
            // color="#3b5998"
            size={30}
            cursor="pointer"
          />
          <span className="spacer" />
          <FaTwitterSquare
            onClick={e => this.onPop(e, "twitter")}
            // color="#00acee"
            size={30}
            cursor="pointer"
          />
          <span className="spacer" />
          <FaLinkedin
            onClick={e => this.onPop(e, "linkedin")}
            // color="#0e76a8"
            size={30}
            cursor="pointer"
          />
          <span className="spacer" />
          <FaGithubSquare
            onClick={e => this.onPop(e, "github")}
            size={30}
            cursor="pointer"
          />
          <span className="spacer" />
          <ImGoogle2
            onClick={e => this.onPop(e, "google")}
            size={30}
            style={{ paddingBottom: 1.8 , paddingLeft: 1, paddingRight: 1.8 , paddingTop: 1.65, }}
            cursor="pointer"
          />
        </span>
      </span>
    )
  }
}

export default SocialLogin
