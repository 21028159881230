import React from "react"
import CommentBox from "./comment-box-ui"
import SocialLogin from "./social-login"
import { isLoggedIn, getUserParsedData, logout } from "../util/user-auth"

class MyCommentBox extends React.Component {
  state = {
    loginType: "",
    authorID: "",
    authorName: "",
    authorNameIsSet: false,
  }

  componentDidMount() {
    if (isLoggedIn()) {
      const user = getUserParsedData()
      this.setState({
        loginType: user.loginType,
        authorID: user.id,
        authorName: user.name,
        authorNameIsSet: true,
      })
    }
  }

  // fetch our comments from Contentful
  getComments = () => {
    return this.props.contentfulClient
      .getEntries({
        order: "sys.createdAt", // important for determining nested comments
        content_type: "comment",
        "fields.subject": this.props.subjectId,
      })
      .then(response => {
        //    console.log(response)
        return response.items
      })
      .catch(console.error)
  }

  // transform Contentful entries to objects that react-commentbox expects.
  normalizeComment = comment => {
    const { id, createdAt } = comment.sys
    const {
      body,
      author,
      author_id,
      repliedAuthor,
      parentComment,
    } = comment.fields

    return {
      id,
      bodyDisplay: body,
      userNameDisplay: author,
      userID: author_id,
      //timestampDisplay: createdAt.split('T')[0],
      // timestampDisplay: new Date(createdAt).toDateString(),
      timestampDisplay: createdAt,
      belongsToAuthor: false,
      parentCommentId: parentComment
        ? parentComment.fields
          ? parentComment.sys.id
          : null
        : null,
      repliedAuthorDisplay: repliedAuthor,
    }
  }

  // make an API call to post a comment
  comment = (body, parentCommentId = null, repliedAuthor) => {
    return this.props.postData("/create-comment", {
      body,
      parentCommentId,
      loginType: this.state.loginType,
      authorID: this.state.authorID,
      authorName: this.state.authorName,
      subjectId: this.props.subjectId,
      repliedAuthor,
    })
  }

  updateComment = (body, entryID) => {
    return this.props.updateData("/update-comment", {
      body,
      authorName: this.state.authorName,
      subjectId: this.props.subjectId,
      entryID,
    })
  }

  deleteComment = entryID => {
    return this.props.deleteData("/delete-comment", {
      entryID,
    })
  }

  logoutUser = () => {
    logout()
    this.setState({
      loginType: "",
      authorID: "",
      authorName: "",
      authorNameIsSet: false,
    })
  }

  loginCallback = res => {
    if (isLoggedIn()) {
      const { id, name, loginType } = JSON.parse(res)
      this.setState({
        loginType: loginType,
        authorID: id,
        authorName: name,
        authorNameIsSet: true,
      })
    }
  }

  // will be shown when the comment box is disabled
  disabledComponent = props => {
    return <SocialLogin responseCallback={this.loginCallback} currentURL={this.props.currentURL}/>
  }

  render() {
    return (
      <CommentBox
        disabled={!this.state.authorNameIsSet}
        author_id={this.state.authorID}
        authorName={this.state.authorName}
        getComments={this.getComments}
        normalizeComment={this.normalizeComment}
        comment={this.comment}
        updateComment={this.updateComment}
        deleteComment={this.deleteComment}
        disabledComponent={this.disabledComponent}
        logout={this.logoutUser}
      />
    )
  }
}

export default MyCommentBox
