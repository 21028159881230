import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import Sharer from "../components/sharer"
import { FacebookProvider, Like } from "react-facebook"

const SocialHeader = ({
  data,
  currentURL,
  facebook_appID,
  isFooter = false,
}) => {
  const { markdownRemark } = data
  const { frontmatter } = markdownRemark
  const Image = frontmatter.authorImage
    ? frontmatter.authorImage.childImageSharp.fluid
    : ""
  return (
    <div className="sub-header">
      <Link to="/about">
        <div className={isFooter ? "avatar-border -footer" : "avatar-border"}>
          {Image ? (
            <Img
              fluid={Image}
              objectFit="cover"
              objectPosition="50% 50%"
              alt={"author avatar"}
              className="avatar"
            />
          ) : (
            ""
          )}
        </div>
      </Link>
      <div className="content">
        <Link to="/about">
          <div className="sub-content">
            {isFooter ? (
              <div className="author-tag">Author</div>
            ) : (
              <div className="margin-sub-content" />
            )}
            {frontmatter.author}
            <time>
              {!isFooter ? frontmatter.date : frontmatter.authorDescription}
            </time>
          </div>
        </Link>
        <div className="social">
          <Sharer url={currentURL} title={frontmatter.title} />
          <div className="like-button">
            <FacebookProvider appId={facebook_appID}>
              <Like href={currentURL} colorScheme="dark" />
            </FacebookProvider>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SocialHeader
