import React from "react"
import Timestamp from "../components/timestamp"
import IconButton from "@material-ui/core/IconButton"
// import Menu from "@material-ui/core/Menu"
// import MenuItem from "@material-ui/core/MenuItem"
import Popover from "@material-ui/core/Popover"
import Button from "@material-ui/core/Button"
import MoreHorizIcon from "@material-ui/icons/MoreHoriz"
import { GiTalk } from "react-icons/gi"
import { CgSync } from "react-icons/cg"
import MDEditor, { commands } from "@uiw/react-md-editor"
import Avatar from "@material-ui/core/Avatar"
import DiceAvatars from "@dicebear/avatars"
import identicons from "@dicebear/avatars-identicon-sprites"
import bots from "@dicebear/avatars-bottts-sprites"

class CommentBox extends React.Component {
  _isMounted = false
  constructor(props) {
    super(props)

    this.state = {
      comments: null,
      comment: "",
      reply: "",
      edit: "",
      showValidationComment: false,
      showValidationReply: false,
      showValidationEdit: false,
      onSendComment: false,
      onSendReply: false,
      onSendEdit: false,
      onReload: false,
      commentIdToShowSync: null,
      commentIdToReplyTo: null,
      commentAuthorToReplyTo: null,
      parentIdToReplyTo: null,
      commentIdToEditTo: null,
      selectedCommentID: null,
      contractedComments: {},
      anchorElMain: null,
      anchorEl: null,
    }

    this.comment = this.comment.bind(this)
    this.reply = this.reply.bind(this)
    this.clearInput = this.clearInput.bind(this)
    this.load = this.load.bind(this)

    this.onUpVote = this.onUpVote.bind(this)
    this.onDownVote = this.onDownVote.bind(this)
    this.onToggleContract = this.onToggleContract.bind(this)
    this.onShowReply = this.onShowReply.bind(this)
    this.onShowEdit = this.onShowEdit.bind(this)
    this.onHideReply = this.onHideReply.bind(this)
    this.onCancelEdit = this.onCancelEdit.bind(this)
    this.onComment = this.onComment.bind(this)
    this.onReply = this.onReply.bind(this)
    this.onEdit = this.onEdit.bind(this)
    this.renderComment = this.renderComment.bind(this)
    this.onChangeComment = this.onChangeComment.bind(this)
    this.onChangeReply = this.onChangeReply.bind(this)
    this.onChangeEdit = this.onChangeEdit.bind(this)
    this.onReload = this.onReload.bind(this)
    this.onFocusEditor = this.onFocusEditor.bind(this)

    this.handleClickLoginName = this.handleClickLoginName.bind(this)
    this.handleClickMenu = this.handleClickMenu.bind(this)
    this.handleCloseMenu = this.handleCloseMenu.bind(this)
    this.handleDeleteMenu = this.handleDeleteMenu.bind(this)
    this.handleLogoutMenu = this.handleLogoutMenu.bind(this)
  }

  componentDidMount() {
    this.load()
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  handleClickMenu = event => {
    this.setState({
      selectedCommentID: event.currentTarget.value,
      anchorEl: event.currentTarget,
    })
  }

  handleClickLoginName = event => {
    this.setState({
      anchorElMain: event.currentTarget,
    })
  }

  handleCloseMenu = e => {
    this.setState({
      anchorEl: null,
      anchorElMain: null,
    })
  }

  handleDeleteMenu = e => {
    this.deleteComment(this.state.selectedCommentID)
    this.setState({
      selectedCommentID: null,
      anchorEl: null,
      anchorElMain: null,
    })
  }

  handleLogoutMenu = e => {
    this.logout()
    this.setState({
      selectedCommentID: null,
      anchorEl: null,
      anchorElMain: null,
    })
  }

  comment() {
    return this.props
      .comment(this.state.comment)
      .then(this.clearInput)
      .then(this.load)
  }

  deleteComment(commentID) {
    return this.props
      .deleteComment(commentID)
      .then(this.clearInput)
      .then(this.load)
  }

  reply() {
    return this.props
      .comment(
        this.state.reply,
        this.state.parentIdToReplyTo,
        this.state.commentAuthorToReplyTo
      )
      .then(this.clearInput)
      .then(this.load)
  }

  edit() {
    return this.props
      .updateComment(this.state.edit, this.state.commentIdToEditTo)
      .then(this.clearInput)
      .then(this.load)
  }

  logout() {
    this.props.logout()
    this.clearInput()
  }

  clearInput() {
    return this.setState({
      comment: "",
      reply: "",
      edit: "",
      showValidationComment: false,
      showValidationReply: false,
      showValidationEdit: false,
      onSendComment: false,
      onSendReply: false,
      onSendEdit: false,
      commentIdToReplyTo: null,
      commentAuthorToReplyTo: null,
      parentIdToReplyTo: null,
      commentIdToEditTo: null,
      selectedCommentID: null,
    })
  }

  load() {
    this._isMounted = true
    return this.props.getComments().then(comments => {
      if (this._isMounted) this.setState({ comments })
      return comments
    })
  }

  onUpVote(e) {
    this.props.upVote(e.currentTarget.value)
  }

  onDownVote(e) {
    this.props.downVote(e.currentTarget.value)
  }

  onToggleContract(e) {
    e.preventDefault()
    const commentId = e.currentTarget.value

    this.setState({
      contractedComments: Object.assign({}, this.state.contractedComments, {
        [commentId]: !this.state.contractedComments[commentId],
      }),
    })
  }

  onShowReply(e, comment_id, authorToReply) {
    const parentID = e.currentTarget.value
    const reply =
      this.state.parentIdToReplyTo !== parentID ? "" : this.state.reply

    this.setState({
      reply,
      parentIdToReplyTo: parentID,
      commentIdToReplyTo: comment_id,
      commentAuthorToReplyTo: authorToReply,
    })
  }

  onShowEdit(e) {
    const commentId = e.currentTarget.value
    const edit =
      this.state.commentIdToEditTo !== commentId ? "" : this.state.edit
    this.setState({
      edit,
      commentIdToEditTo: commentId,
    })
  }

  onHideReply(e) {
    this.setState({
      reply: "",
      showValidationReply: false,
      parentIdToReplyTo: null,
      commentIdToReplyTo: null,
      commentAuthorToReplyTo: null,
    })
  }

  onCancelEdit() {
    this.setState({
      edit: "",
      showValidationEdit: false,
      commentIdToEditTo: null,
    })
  }

  onReload(e) {
    const commentId = e.currentTarget.value
    this.setState({
      onReload: true,
      commentIdToShowSync: commentId,
    })
    //this.clearInput()
    this._isMounted = true
    return this.props.getComments().then(comments => {
      if (this._isMounted) {
        this.setState({
          comments,
          commentIdToShowSync: null,
          onReload: false,
        })
      }
      return comments
    })
  }

  onComment(e) {
    e.preventDefault()

    if (this.props.author_id === null || this.props.author_id === "") return

    var commentText = this.state.comment
    if (commentText.trim().length <= 0) {
      this.setState({
        showValidationComment: true,
      })
    } else {
      this.setState({
        onSendComment: true,
        showValidationComment: false,
      })
      this.comment()
    }
  }

  onReply(e) {
    e.preventDefault()

    if (this.props.author_id === null || this.props.author_id === "") return

    var commentText = this.state.reply
    if (commentText.trim().length <= 0) {
      this.setState({
        showValidationReply: true,
      })
    } else {
      this.setState({
        onSendReply: true,
        showValidationReply: false,
      })
      this.reply()
    }
  }

  onEdit(e) {
    e.preventDefault()

    if (this.props.author_id === null || this.props.author_id === "") return

    var commentText = this.state.edit
    if (commentText.trim().length <= 0) {
      this.setState({
        showValidationEdit: true,
      })
    } else {
      this.setState({
        onSendEdit: true,
        showValidationEdit: false,
      })
      this.edit()
    }
  }

  onChangeComment(value) {
    if (value.trim().length > 0) {
      this.setState({
        showValidationComment: false,
      })
    }
    this.setState({
      comment: value,
    })
  }

  onChangeReply(value) {
    if (value.trim().length > 0) {
      this.setState({
        showValidationReply: false,
      })
    }
    this.setState({
      //reply: e.currentTarget.value,
      reply: value,
    })
  }

  onChangeEdit(value) {
    if (value.trim().length > 0) {
      this.setState({
        showValidationEdit: false,
      })
    }
    this.setState({
      edit: value,
    })
  }

  onFocusEditor(e) {
    e.preventDefault()
    var defValue = e.target.defaultValue
    if (defValue !== undefined) {
      e.target.selectionStart = defValue.length
      e.target.selectionEnd = defValue.length
    }
  }

  renderComment(comment) {
    const classNames = ["comment"]

    if (this.state.commentIdToReplyTo === comment.id) {
      classNames.push("replying-to")
    }
    if (comment.belongsToAuthor) {
      classNames.push("belongs-to-author")
    }

    if (comment.className) {
      classNames.push(comment.className)
    }

    var month = new Date().getMonth()
    let options = {
      width: month === 10 ? 30 : 26,
      height: month === 10 ? 30 : 26,
    }
    let avatars = new DiceAvatars(month === 10 ? bots : identicons, options) //if november month change to bots sprite

    return (
      <div
        className={classNames
          .map(className => this.prefix(className))
          .join(" ")}
      >
        <div className={this.prefix(`level-${comment.level}`)}>
          <div className={this.prefix("comment-content")}>
            <span className="cb-user-avatar-comment-details">
              {this.props.usersHaveAvatars ? (
                <Avatar className="cb-user-avatar">
                  <img
                    src={`data:image/svg+xml;utf8,${encodeURIComponent(
                      avatars.create(comment.userID)
                    )}`}
                    alt={comment.userID}
                  />
                </Avatar>
              ) : null}

              <span className="cb-user-comment-details">
                <span className={this.prefix("user-name")}>
                  {comment.userNameDisplay}
                </span>
                {comment.repliedAuthorDisplay ? (
                  <span>
                    <GiTalk className={this.prefix("reply-icon")} />
                    <span className={this.prefix("parent-author")}>
                      {comment.repliedAuthorDisplay
                        ? comment.repliedAuthorDisplay
                        : ""}
                    </span>
                  </span>
                ) : (
                  ""
                )}

                <span className={this.prefix("timestamp")}>
                  <span> • </span>
                  <Timestamp
                    relative
                    date={new Date(comment.timestampDisplay)}
                    autoUpdate
                  />
                </span>

                {!this.props.disabled &&
                this.props.author_id === comment.userID ? (
                  <span>
                    <IconButton
                      className="cb-menu-button"
                      disableRipple={true}
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      disabled={
                        this.state.onSendComment ||
                        this.state.onSendReply ||
                        this.state.onSendEdit ||
                        this.state.onReload
                      }
                      size="small"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      value={comment.id}
                      onClick={this.handleClickMenu}
                    >
                      <MoreHorizIcon />
                    </IconButton>

                    <Popover
                      elevation={0}
                      id={
                        Boolean(this.state.anchorEl)
                          ? "simple-popover"
                          : undefined
                      }
                      open={Boolean(this.state.anchorEl)}
                      anchorEl={this.state.anchorEl}
                      onClose={this.handleCloseMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                    >
                      <Button
                        onClick={this.handleLogoutMenu}
                        variant="outlined"
                        className="cb-menuitem-logout"
                      >
                        Logout
                      </Button>
                    </Popover>
                  </span>
                ) : null}
              </span>
            </span>

            <div className={this.prefix("comment-body")}>
              {this.state.commentIdToEditTo === comment.id ? (
                <div className={this.prefix("form-wrapper")}>
                  <form
                    className={this.prefix("edit-form")}
                    onSubmit={this.onEdit}
                  >
                    <div className={this.prefix("form-element")}>
                      <MDEditor
                        textareaProps={{
                          maxLength: 8000,
                          className: "cb-comment-textarea",
                        }}
                        name="edit"
                        value={comment.bodyDisplay}
                        //value={this.state.edit}
                        onChange={this.onChangeEdit}
                        onFocus={e => this.onFocusEditor(e)}
                        preview="edit"
                        visiableDragbar={false}
                        commands={[
                          commands.bold,
                          commands.hr,
                          commands.italic,
                          commands.strikethrough,
                          commands.title,
                          commands.quote,
                          commands.code,
                          commands.divider,
                          commands.codeEdit,
                          commands.codeLive,
                        ]}
                      />
                    </div>

                    <div className="cb-comment-footer-edit-mode">
                      <span>
                        {comment.replies.length > 0 ? (
                          <button
                            className={this.prefix("toggle")}
                            value={comment.id}
                            onClick={this.onToggleContract}
                          >
                            {this.state.contractedComments[comment.id]
                              ? this.props.expandButtonContent
                              : this.props.contractButtonContent}
                          </button>
                        ) : null}

                        <button
                          className="cb-edit-cancel"
                          value={comment.id}
                          onClick={this.onCancelEdit}
                        >
                          {this.props.cancelEditButtonContent}
                        </button>

                        <button
                          type="submit"
                          className="cb-edit-save"
                          disabled={
                            this.state.onSendComment ||
                            this.state.onSendReply ||
                            this.state.onSendEdit ||
                            this.state.onReload
                          }
                        >
                          {!this.state.onSendEdit
                            ? this.props.showSaveButtonContent
                            : this.props.savingButtonContent}
                        </button>

                        {this.state.showValidationEdit ? (
                          <span className="cb-post-validation-edit">
                            {this.props.postCommentValidationContent}
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </form>
                </div>
              ) : (
                <MDEditor.Markdown source={comment.bodyDisplay} />
              )}
            </div>

            <div className={this.prefix("comment-footer")}>
              {comment.replies.length > 0 ? (
                this.state.commentIdToEditTo === comment.id ? null : (
                  <button
                    className={this.prefix("toggle")}
                    value={comment.id}
                    onClick={this.onToggleContract}
                  >
                    {this.state.contractedComments[comment.id]
                      ? this.props.expandButtonContent
                      : this.props.contractButtonContent}
                  </button>
                )
              ) : null}

              {this.state.commentIdToReplyTo === comment.id ? (
                <button
                  className="cb-hide-reply"
                  value={comment.id}
                  onClick={this.onHideReply}
                >
                  {this.props.hideReplyButtonContent}
                </button>
              ) : this.state.commentIdToEditTo === comment.id ? null : (
                <span>
                  {!this.props.disabled &&
                  this.props.author_id === comment.userID ? (
                    <button
                      className={this.prefix("show-edit")}
                      value={comment.id}
                      onClick={this.onShowEdit}
                    >
                      {this.props.showEditButtonContent}
                    </button>
                  ) : null}

                  <button
                    className={this.prefix("show-reply")}
                    //value={comment.id}
                    value={
                      comment.parentCommentId
                        ? comment.parentCommentId
                        : comment.id
                    }
                    onClick={e =>
                      this.onShowReply(e, comment.id, comment.userNameDisplay)
                    }
                  >
                    {this.props.showReplyButtonContent}
                  </button>

                  {this.state.onReload &&
                  this.state.commentIdToShowSync === comment.id ? (
                    <span className="cb-syncing-text">
                      {this.props.showSyncingContent}
                    </span>
                  ) : (
                    <IconButton
                      className="cb-button-reload"
                      disableRipple={true}
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      disabled={
                        this.state.onSendComment ||
                        this.state.onSendReply ||
                        this.state.onSendEdit ||
                        this.state.onReload
                      }
                      size="small"
                      value={comment.id}
                      onClick={this.onReload}
                    >
                      <CgSync />
                    </IconButton>
                  )}
                </span>
              )}
            </div>
          </div>

          <div className="cb-reply-form-content">
            {this.state.commentIdToReplyTo === comment.id ? (
              <div className={this.prefix("form-wrapper")}>
                <form
                  className={this.prefix("reply-form")}
                  onSubmit={this.onReply}
                >
                  <div className={this.prefix("form-element-main")}>
                    <MDEditor
                      textareaProps={{
                        maxLength: 8000,
                        className: "cb-comment-textarea",
                      }}
                      name="reply"
                      value={this.state.reply}
                      onChange={this.onChangeReply}
                      preview="edit"
                      visiableDragbar={false}
                      commands={[
                        commands.bold,
                        commands.hr,
                        commands.italic,
                        commands.strikethrough,
                        commands.title,
                        commands.quote,
                        commands.code,
                        commands.divider,
                        commands.codeEdit,
                        commands.codeLive,
                      ]}
                    />
                    {this.props.disabled ? (
                      <this.props.disabledComponent {...this.props} />
                    ) : null}
                  </div>
                  <div>
                    {!this.props.disabled ? (
                      <span>
                        <span className="cb-login-name">
                          <span className="text">
                            Login name: {this.props.authorName}
                          </span>
                        </span>
                        <Button
                          type="submit"
                          variant="contained"
                          className="cb-comment-reply-button"
                          disableRipple={true}
                          disableTouchRipple={true}
                          disableFocusRipple={true}
                          disabled={
                            this.state.onSendComment ||
                            this.state.onSendReply ||
                            this.state.onSendEdit ||
                            this.state.onReload
                          }
                          disableElevation
                        >
                          {!this.state.onSendReply
                            ? this.props.postReplyButtonContent
                            : this.props.publishingButtonContent}
                        </Button>
                        {this.state.showValidationReply ? (
                          <span className="cb-post-validation">
                            {this.props.postReplyValidationContent}
                          </span>
                        ) : null}
                      </span>
                    ) : null}
                    {!this.props.disabled
                      ? this.props.postButtonExtraContent
                      : null}
                  </div>
                </form>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    )
  }

  renderComments(comments) {
    return comments.map(comment => {
      return (
        <li key={comment.id} className=".comment-and-replies">
          {this.renderComment(comment)}
          <ul
            className="comment-replies"
            style={{ paddingLeft: this.props.levelPadding }}
          >
            {this.state.contractedComments[comment.id]
              ? null
              : this.renderComments(comment.replies)}
          </ul>
        </li>
      )
    })
  }

  get renderedComments() {
    if (!this.state.comments) {
      return <li className="comment-loading">{this.props.loadingContent}</li>
    }

    const comments = []
    const references = {}

    this.state.comments.forEach(comment => {
      const {
        id,
        votes,
        bodyDisplay,
        userAvatarUrl,
        userNameDisplay,
        userID,
        timestampDisplay,
        belongsToAuthor,
        parentCommentId,
        repliedAuthorDisplay,
        className,
      } = this.props.normalizeComment(comment)

      references[id] = {
        id,
        votes,
        bodyDisplay,
        userAvatarUrl,
        userNameDisplay,
        userID,
        parentCommentId,
        repliedAuthorDisplay,
        timestampDisplay,
        belongsToAuthor,
        replies: [],
        level: 0,
        className,
      }

      if (parentCommentId) {
        references[parentCommentId].replies.push(references[id])
        references[id].level = references[parentCommentId].level + 1
      } else {
        comments.push(references[id])
      }
    })

    return this.renderComments(comments)
  }

  prefix(className) {
    return `${this.props.classPrefix}${className}`
  }

  render() {
    return (
      <div className={this.props.className}>
        <div className={this.prefix("header")}>
          <form
            className={this.prefix("comment-form")}
            onSubmit={this.onComment}
          >
            <div className={this.prefix("form-element-main")}>
              <h2>Discussion</h2>
              {/* <textarea
                name="comment"
                rows={this.props.textareaRows}
                value={this.state.comment}
                onChange={this.onChangeComment}
                disabled={this.props.disabled}
              /> */}
              <MDEditor
                textareaProps={{
                  maxLength: 8000,
                  className: "cb-comment-textarea",
                  autoFocus: false,
                }}
                name="comment"
                value={this.state.comment}
                onChange={this.onChangeComment}
                preview="edit"
                visiableDragbar={false}
                commands={[
                  commands.bold,
                  commands.hr,
                  commands.italic,
                  commands.strikethrough,
                  commands.title,
                  commands.quote,
                  commands.code,
                  commands.divider,
                  commands.codeEdit,
                  commands.codeLive,
                ]}
              />
              {this.props.disabled ? (
                <this.props.disabledComponent {...this.props} />
              ) : null}
            </div>
            <div>
              {!this.props.disabled ? (
                <span>
                  <span className="cb-login-name">
                    <Button
                      className="button-text"
                      disableRipple={true}
                      disableFocusRipple={true}
                      disableTouchRipple={true}
                      disabled={
                        this.state.onSendComment ||
                        this.state.onSendReply ||
                        this.state.onSendEdit ||
                        this.state.onReload
                      }
                      size="small"
                      aria-controls="simple-menu"
                      aria-haspopup="true"
                      onClick={this.handleClickLoginName}
                    >
                      Login name: {this.props.authorName}
                    </Button>

                    <Popover
                      elevation={0}
                      id={
                        Boolean(this.state.anchorElMain)
                          ? "simple-popover"
                          : undefined
                      }
                      open={Boolean(this.state.anchorElMain)}
                      anchorEl={this.state.anchorElMain}
                      onClose={this.handleCloseMenu}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <Button
                        onClick={this.handleLogoutMenu}
                        variant="outlined"
                        className="cb-menuitem-logout"
                      >
                        Logout
                      </Button>
                    </Popover>
                  </span>
                  <Button
                    type="submit"
                    variant="contained"
                    className="cb-comment-post-button"
                    disableRipple={true}
                    disableTouchRipple={true}
                    disableFocusRipple={true}
                    disabled={
                      this.state.onSendComment ||
                      this.state.onSendReply ||
                      this.state.onSendEdit ||
                      this.state.onReload
                    }
                    disableElevation
                  >
                    <span>
                      {!this.state.onSendComment
                        ? this.props.postCommentButtonContent
                        : this.props.publishingButtonContent}
                    </span>
                  </Button>
                  {this.state.showValidationComment ? (
                    <span className="cb-post-validation">
                      {this.props.postCommentValidationContent}
                    </span>
                  ) : null}
                </span>
              ) : null}
              {!this.props.disabled ? this.props.postButtonExtraContent : null}
            </div>
          </form>
        </div>
        <div className="comment-comments-body">
          <ul className="comment-comments">{this.renderedComments}</ul>
        </div>
      </div>
    )
  }

  static upVote(commentId) {}

  static downVote(commentId) {}

  static getComments() {
    return new Promise()
  }

  static normalizeComment(comment) {
    return comment
  }

  static comment(body, parentCommentId = null, repliedAuthor) {
    return new Promise()
  }

  static disabledComponent(props) {
    return (
      <div>
        Replace with a component that logs in your user or gets their name.
      </div>
    )
  }

  static get defaultProps() {
    const {
      upVote,
      downVote,
      getComments,
      normalizeComment,
      comment,
      disabledComponent,
    } = this

    return {
      classPrefix: "cb-",
      className: "commentbox",
      disabled: true,
      usersHaveAvatars: true,
      levelPadding: 25,
      textareaRows: 7,
      loadingContent: "Loading...",
      expandButtonContent: "[+]",
      contractButtonContent: "[-]",
      showReplyButtonContent: "reply",
      showEditButtonContent: "edit",
      hideReplyButtonContent: "cancel",
      showSaveButtonContent: "save",
      cancelEditButtonContent: "cancel",
      postReplyButtonContent: "Post Reply",
      postCommentButtonContent: "Post",
      publishingButtonContent: "Publishing...",
      savingButtonContent: "saving...",
      showSyncingContent: "syncing...",
      postCommentValidationContent: "Post can't be empty",
      postReplyValidationContent: "Reply can't be empty",
      postButtonExtraContent: null,
      disabledComponent,
      upVote,
      downVote,
      getComments,
      normalizeComment,
      comment,
    }
  }
}

export default CommentBox
